'use strict';

import React from 'react';
import Utils from '../jskit/general/Utils';
import ReactTooltip from 'react-tooltip';
import MonitoringChecks from './MonitoringChecks.jsx';
import FeatureSection from './FeatureSection.jsx';
import FreeTrialProgressBar from './FreeTrialProgressBar.jsx';
import UIABanner from './UIABanner';
import './Launchpad.css';

const groupsDefinition = {
  Web: {
    icon: 'fa-browser',
    tooltip: 'Web checks monitor your home page and key customer pathways',
  },
  Network: {
    icon: 'fa-server',
    tooltip: 'Network checks monitor connectivity and domain registration details',
  },
  Email: {
    icon: 'fa-envelope',
    tooltip: 'Monitor email servers that send or receive email to catch downtime affecting delivery',
  },
  Custom: {
    icon: 'fa-sliders-v',
    tooltip: 'Monitor jobs and processes, issuing alerts according to whether a webhook or heartbeat is received',
  },
};

function getAdditionalFeaturesDef(dashboardURL, contactURL, statusPageURL, reportURL, integrationURL) {
  return [
    {
      title: 'Dashboards',
      subtitle: 'A customizable statistic overview of checks currently running within your account.',
      icon: <i className="fas fa-clone feature-icon"></i>,
      accessory: (
        <a className="title" href={`${dashboardURL}?pop=initial&gobackoncancel=true`}>
          Customize Dashboard
        </a>
      ),
      supportURL: 'https://support.uptime.com/hc/en-us/articles/115002557925-Overview-of-Dashboard',
    },
    {
      title: 'Private locations',
      // eslint-disable-next-line max-len
      subtitle:
        'Monitor internal applications and services using an instance of Uptime.com run from a private probe server.',
      icon: <i className="fas fa-map-marker-alt feature-icon"></i>,
      accessory: (
        <a className="title" href={`${contactURL}?subject=User%20request%20for%20Private%20Locations`} target="_blank">
          Request Private Locations
        </a>
      ),
      // eslint-disable-next-line max-len
      supportURL:
        'https://support.uptime.com/hc/en-us/articles/360012622239-Private-Location-Monitoring-Setup-and-Troubleshooting',
    },
    {
      title: 'Subaccounts',
      subtitle: 'Separate accounts, systems, or teams and manage checks and reports for each account.',
      icon: <i className="fas fa-users feature-icon"></i>,
      accessory: (
        <a className="title" href={`${contactURL}?subject=User%20request%20for%20Subaccounts`} target="_blank">
          Request Subaccounts
        </a>
      ),
      supportURL: 'https://support.uptime.com/hc/en-us/articles/360015999479-Uptime-com-Subaccounts',
    },
    {
      title: 'Status pages',
      // eslint-disable-next-line max-len
      subtitle:
        'A Status Page conveys info on scheduled maintenance, downtime events, and general system uptime to your site.',
      icon: <i className="fas fa-badge-check feature-icon"></i>,
      accessory: (
        <a href={`${statusPageURL}?pop=initial&gobackoncancel=true`} className="title">
          <i className="fal fa-plus"></i>Add status page
        </a>
      ),
      // eslint-disable-next-line max-len
      supportURL:
        'https://support.uptime.com/hc/en-us/articles/360016256820-Setup-Instructions-for-your-Uptime-com-Status-Page',
    },
    {
      title: 'SLA reports',
      // eslint-disable-next-line max-len
      subtitle:
        'Report the uptime and performance data from specific checks or systems for the purposes of SLA accountability.',
      icon: <i className="fas fa-file-chart-line feature-icon"></i>,
      accessory: (
        <a href={`${reportURL}?pop=initial&gobackoncancel=true`} className="title">
          <i className="fal fa-plus"></i>Create a Report
        </a>
      ),
      supportURL: 'https://support.uptime.com/hc/en-us/articles/360017118860-Creating-an-Uptime-com-SLA-Report-',
    },
    {
      title: 'Third-party Integration',
      subtitle: 'Use Uptime.com to deliver critical metric and alert data to key tools you trust and use.',
      icon: <i className="fas fa-chart-network feature-icon"></i>,
      accessory: (
        <a className="title" href={`${integrationURL}?pop=initial&gobackoncancel=true`}>
          <i className="fal fa-plus"></i>Add integration
        </a>
      ),
      supportURL: 'https://support.uptime.com/hc/en-us/articles/115002534509-Overview-of-Push-Notifications',
    },
  ];
}

const supportDef = [
  {
    title: 'Quickstart Guide',
    subtitle: 'Review the basics and get up to speed with Uptime.com monitoring and account setup.',
    icon: <i className="fas fa-rocket feature-icon"></i>,
    accessory: (
      <a
        className="title"
        href="https://support.uptime.com/hc/en-us/articles/360001311220-Getting-Started-With-Uptime-com"
        target="_blank"
      >
        Get Started
      </a>
    ),
  },
  {
    title: 'Documentation',
    // eslint-disable-next-line max-len
    subtitle: 'View setup instructions for checks, integrations, status pages and more from our support documentation.',
    icon: <i className="fas fa-book-reader feature-icon"></i>,
    accessory: (
      <a className="title" href="https://support.uptime.com" target="_blank">
        Find Answers
      </a>
    ),
  },
  {
    title: 'Uptime.com Video Tutorials',
    subtitle: 'Browse a growing library of video resources.',
    icon: <i className="fab fa-youtube feature-icon"></i>,
    accessory: (
      <a className="title" href="https://www.youtube.com/channel/UCFSfPCnC0x08CPobke0vEHA/featured" target="_blank">
        View Video Library
      </a>
    ),
  },
];

export default class LaunchpadController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  renderSubscriptionButton() {
    const {freeTrialRemaining, freeTrialTotal} = this.props;
    const daysUsed = Math.max(0, freeTrialTotal - freeTrialRemaining);
    const title = `${freeTrialRemaining} day(s) left`;
    const percentUsed = Math.round((daysUsed / freeTrialTotal) * 100);

    if (!this.props.showSubscribeNow) {
      return false;
    }

    return (
      <div className="row w-100 m-0">
        <div className="col-12 col-sm-5 offset-sm-2 text-center order-2 order-sm-1" data-tip={title}>
          <div className="text-muted mb-2 free-trial">Free Trial</div>
          <FreeTrialProgressBar className="ml-auto mr-auto" percent={percentUsed} title={title} />
        </div>
        <div className="col-12 col-sm-5 p-0 text-right order-1 order-sm-2">
          <a href={this.props.subscriptionURL} className="btn btn-primary w-100">
            Subscribe now
          </a>
        </div>
      </div>
    );
  }

  renderWelcomeMessage() {
    if (!this.props.isSignup) {
      return false;
    }
    const {userName} = this.props;
    return (
      <div id="launchpad-welcome" className="alert alert-info alert-dismissible fade show" role="alert">
        <h4 className="mb-0">Welcome to Uptime.com, {userName}!</h4>
        <p className="pb-0 mb-0">
          Browse our selection of check types, see some of the features in your account, or get up and running with
          Uptime Intelligent Analyzer.
        </p>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  render() {
    const items = getAdditionalFeaturesDef(
      this.props.dashboardURL,
      this.props.supportURL,
      this.props.statusPageURL,
      this.props.reportURL,
      this.props.integrationURL
    );
    return (
      <React.Fragment>
        <div className="row page-title-row w-100 ml-0 mr-0 mb-3 mb-sm-0">
          <div className="col-6 col-md-3 pl-0 mr-0 ml-0">
            <h3 className="pb-3">Launchpad</h3>
          </div>
          <div className="col-6 col-md-5 offset-md-4 col-lg-4 offset-lg-5 pr-0 pl-0">
            {this.renderSubscriptionButton()}
          </div>
        </div>
        {this.renderWelcomeMessage()}
        <div>
          <MonitoringChecks {...this.props} groupsDefinition={groupsDefinition} />
          {this.props.prominentMesBanner && (
            <UIABanner
              url={this.props.monitorEntireSiteURL}
              value={this.props.defaultMesURL}
              csrftoken={this.props.csrftoken}
            />
          )}
          <FeatureSection title="Additional features" items={items} isReadOnly={!this.props.hasWriteAccess} />
          {!this.props.prominentMesBanner && (
            <UIABanner
              url={this.props.monitorEntireSiteURL}
              value={this.props.defaultMesURL}
              csrftoken={this.props.csrftoken}
            />
          )}
          <FeatureSection title="Support" items={supportDef} isReadOnly={false} />
        </div>
        <ReactTooltip
          html={true}
          place="bottom"
          delayHide={400}
          backgroundColor={'#E9E7F3'}
          textColor={'#131C22'}
          className="launchpad-tooltip"
        />
      </React.Fragment>
    );
  }
}
